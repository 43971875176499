import * as React from 'react'
import { useEffect, useState } from "react"
import { FunctionComponent } from 'react'
import {
  TextField,
  Typography,
  Button,
  CircularProgress,
  DialogActions,
  Snackbar
} from '@material-ui/core'
import styled from 'styled-components'
import Mutation from 'react-apollo/Mutation'
import { Link, navigate } from 'gatsby'
import { TransparentCard } from '../ui/TransparentCard'
import { setLocalToken, getTokenUserRole, getTokenUserId } from '../../utils/client'
import { LOGIN_USER, LOGIN_USER_WITH_KEY } from '../../apollo/mutations'
import { Location } from '@reach/router';
import queryString from 'query-string'
import LogRocket from 'logrocket';
import { BorderAll } from '@material-ui/icons'
import { useMutation } from "@apollo/react-hooks"
import { makeStyles } from '@material-ui/styles'
const logo = require('../../images/logo-white.png')

interface WelcomeProps {
  name: string
}

const generateLabel = (label, error) => {
  if (error) return `${label} - ${error}`
  return label
}

const useStyles = makeStyles({
  loginButton: props => ({
    width: "100%"
  })
})

const CreateAccount: FunctionComponent<WelcomeProps> = props => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [customError, setCustomError] = useState(null)
  const [redirectMessageShown, setRedirectMessageShown] = useState(false)
  const queryStrings = typeof window !== 'undefined' && queryString.parse(location.hash);
  const [loginSent, setLoginSent] = useState(false)
  const classes = useStyles();
  const [loginUserWithKey] = useMutation(LOGIN_USER_WITH_KEY, {

    update: (
      cache,
      {
        data: {
          login: { token, user },
        },
      },
    ) => {
      setLocalToken(token)
      cache.writeData({
        data: { userId: user.id },
      })
      navigate(getTokenUserRole() === 'HOMEOWNER' ? "/jobs" : "/contractor/jobs")
    }
  })

  useEffect(() => {
    if (getTokenUserRole() && getTokenUserId()) {
      if (process.env.GATSBY_LOGROCKET_ACTIVE === 'true') {
        LogRocket.identify(getTokenUserId());
      }
      if (queryStrings.job_id) {
        navigate(`/contractor/bidding-board/#job_id=${queryStrings.job_id}`)
      }
      navigate(getTokenUserRole() === 'HOMEOWNER' ? "/jobs" : "/contractor/jobs")
    }
  }, [])

  return (
    <>
      <Location>
        {({ location }) => {
          const queryStrings = queryString.parse(location.search);
          if (queryStrings.id && queryStrings.oneTimeKey) {
            if (!loginSent) {
              setLoginSent(true)
              loginUserWithKey({
                variables: {
                  id: queryStrings.id,
                  oneTimeKey: queryStrings.oneTimeKey
                }
              })
            }

          }
          return (
            <Mutation
              mutation={LOGIN_USER}
              update={(
                cache,
                {
                  data: {
                    login: { token, user },
                  },
                },
              ) => {
                setLocalToken(token)
                cache.writeData({
                  data: { userId: user.id },
                })
              }}
              onCompleted={data => {
                if (!data || !data.login) {
                  setCustomError("Login failed. Please check your information and try again");
                  return
                }
                const {
                  login: { token, user },
                } = data
                if (token !== "") {
                  if (process.env.GATSBY_LOGROCKET_ACTIVE === 'true') {
                    LogRocket.identify(user.id, {
                      email: user.email,
                      role: user.role,
                    });
                  }
                  if (user.role === 'HOMEOWNER' || user.role === 'CONTRACTOR' || user.role === 'ADMIN') {
                    if (queryStrings.job_id) {
                      navigate(`/contractor/bidding-board/#job_id=${queryStrings.job_id}`)
                    } else {
                      switch (user.role) {
                        case 'HOMEOWNER':
                          navigate('/jobs')
                          break;
                        case 'CONTRACTOR':
                          navigate('/contractor/jobs')
                          break;
                        case 'ADMIN':
                          navigate('/admin/')
                          break;
                        default:
                          return
                      }
                    }
                  } else {
                    console.error("Login failed-- could not find a homeowner or contractor for this user.")
                  }
                }
              }}
            >
              {(LoginUser, { loading, error, data }) => {
                if (error) {
                  if (error.graphQLErrors.length && error.graphQLErrors[0].message === "User not yet verified.") {
                    setCustomError("You have not verified your email, please do this first");
                  } else {
                    setCustomError(error);
                  }
                }
                return (
                  <>
                    <Internal>
                      <LogoAvatar src={logo}/>
                      <TransparentCard style={{position: 'relative'}}>
                        <form
                          onSubmit={e => {
                            e.preventDefault()
                            LoginUser({
                              variables: {
                                email,
                                password,
                              },
                            })
                          }}
                        >
                          <FormInput
                            error={false}
                            id="username"
                            label={generateLabel("Email", false)}
                            onChange={e => setEmail(e.target.value)}
                            margin="normal"
                            variant="outlined"
                            value={email}
                            type="email"
                          />
                          <FormInput
                            error={false}
                            id="password"
                            label={generateLabel("Password", false)}
                            type="password"
                            onChange={e => setPassword(e.target.value)}
                            margin="normal"
                            variant="outlined"
                            value={password}
                          />
                          <StyledActions>
                            {loading ? (
                              <CircularProgress size={24}/>
                            ) : (
                              <Button
                                type="submit"
                                disabled={loading}
                                className={classes.loginButton}
                              >
                                Login
                              </Button>
                            )}
                          </StyledActions>
                        </form>
                        <Typography variant="subtitle1" style={{position: 'relative', top: 9, bottom: 9, fontSize: 12 }}>
                          <Link to={`/reset`} style={{ color: "#8B6137" }}>
                            Forgot Password?
                          </Link>
                        </Typography>
                      </TransparentCard>
                      <Typography variant="subtitle1" style={{ marginTop: 12, color: "white" }}>
                        Don't Have an Account? <Link to={`/signup/${location.hash}`}
                                                     style={{ color: "#8B6137" }}>Signup</Link>
                      </Typography>
                    </Internal>
                    <Snackbar
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      open={queryStrings.job_id && !redirectMessageShown && queryStrings.verified === undefined}
                      autoHideDuration={5000}
                      onClose={() => setRedirectMessageShown(true)}
                      ContentProps={{
                        "aria-describedby": "message-id",
                      }}
                      message={
                        <span id="message-id">Please log in first</span>
                      }
                    />
                    <ConfirmationSnackbar
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      open={queryStrings.verified !== undefined}
                      autoHideDuration={5000}
                      onClose={() => setRedirectMessageShown(true)}
                      ContentProps={{
                        "aria-describedby": "message-id",
                      }}
                      message={
                        <span id="message-id">You have verified your account please login.</span>
                      }
                    />
                    <ErrorSnackbar
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      open={!!customError}
                      autoHideDuration={5000}
                      onClose={() => setCustomError(null)}
                      ContentProps={{
                        "aria-describedby": "message-id",
                      }}
                      message={
                        <span id="message-id">{customError && customError.toString()}</span>
                      }
                    />
                  </>
                )
              }}
            </Mutation>
          )
        }}
      </Location>

    </>
  )
}

const StyledActions = styled(DialogActions)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 24px -4px -8px -4px;
  padding: 0;
`

const ConfirmationSnackbar = styled(Snackbar)`
  & > div {
    background: linear-gradient(#E2B985, #8B6137);
  }

`

const Internal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  @keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
  }
  animation: fadeIn .5s ease-out;

  > *:nth-child(2) {
    max-width: 92vw;
  }
`

const LogoAvatar = styled.img.attrs({ resizeMode: 'contain' })`
  width: 280px;
  max-width: 90vw;
  margin-bottom: 22px;
`

const FormInput = styled(TextField)`
  width: 100%;
`

const ErrorSnackbar = styled(Snackbar)`
  & > div {
    background-color: darkred;
    * {
      color: white;
    }
  }
`
export default CreateAccount
