import React from 'react'

import SEO from '../components/seo/seo'
import Login from '../components/login/login'
import MainWrapper from '../components/ui/MainWrapper'

const IndexPage = () => (
	<MainWrapper>
		<SEO title="Login" keywords={['login', '']} canonicalUrl="https://app.fairmarkethome.com" />
		<Login />
	</MainWrapper>
)

export default IndexPage
